import React, { useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import { Dialog } from "primereact/dialog";
import AddSymbol from "./AddSymbol/AddSymbol";
import ExpirySymbol from "./ExpirySymbol/ExpirySymbol";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import { confirmAlert } from "react-confirm-alert";
import Loading from "react-loading";
const backend = new Backend();
export default function Header(props) {
  const [show, setShow] = useState(false);

  const [show1, setShow1] = useState(false);

  const [loading, setLoading] = useState(false);

  const symbol_future_delete_all = () => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to delete all future symbol.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let data = {
              token: localStorage.getItem("token"),
              id: localStorage.getItem("id"),
              username: localStorage.getItem("username"),
              server: localStorage.getItem("server"),
            };

            backend.symbol_future_delete_all(data).then((r) => {
              setLoading(false);
              if (r.error == "False") {
                toast.success(r.message, {
                  backgroundColor: Dark.buy,
                  color: Dark.text,
                });
                props.load();
              } else {
                toast.error(r.message, {
                  backgroundColor: Dark.sell,
                  color: Dark.text,
                });
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  const symbol_future_all_sq_active = () => {
    confirmAlert({
      title: "Confirm to Sq Mode",
      message: "Are you sure to all future symbol sq mode.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let data = {
              token: localStorage.getItem("token"),
              id: localStorage.getItem("id"),
              username: localStorage.getItem("username"),
              server: localStorage.getItem("server"),
            };

            backend.symbol_future_sq_all_active(data).then((r) => {
              setLoading(false);
              if (r.error == "False") {
                toast.success(r.message, {
                  backgroundColor: Dark.buy,
                  color: Dark.text,
                });
                props.load();
              } else {
                toast.error(r.message, {
                  backgroundColor: Dark.sell,
                  color: Dark.text,
                });
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  const symbol_future_all_sq_deactive = () => {
    confirmAlert({
      title: "Confirm to Sq Mode",
      message: "Are you sure to all future symbol decative sq mode.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let data = {
              token: localStorage.getItem("token"),
              id: localStorage.getItem("id"),
              username: localStorage.getItem("username"),
              server: localStorage.getItem("server"),
            };

            backend.symbol_future_sq_all_deactive(data).then((r) => {
              setLoading(false);
              if (r.error == "False") {
                toast.success(r.message, {
                  backgroundColor: Dark.buy,
                  color: Dark.text,
                });
                props.load();
              } else {
                toast.error(r.message, {
                  backgroundColor: Dark.sell,
                  color: Dark.text,
                });
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };


  const run_closing = () => {
    confirmAlert({
      title: "Confirm to Run Closing",
      message: "Are you sure to future run closing.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let data = {
              token: localStorage.getItem("token"),
              id: localStorage.getItem("id"),
              username: localStorage.getItem("username"),
              server: localStorage.getItem("server"),
            };

            backend.closing(data).then((r) => {
              setLoading(false);
              if (r.error == "False") {
                toast.success(r.message, {
                  backgroundColor: Dark.buy,
                  color: Dark.text,
                });
                props.load();
              } else {
                toast.error(r.message, {
                  backgroundColor: Dark.sell,
                  color: Dark.text,
                });
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  return (
    <div
      style={{
        borderRadius: 10,
        margin: 5,
        backgroundColor: Dark.background,
        height: 55,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          paddingLeft: 20,
          textTransform: "uppercase",
        }}
      >
        Future Symbol All List
      </div>

      <div
        style={{
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={symbol_future_all_sq_active}
          style={{
            height: 40,
            width: 140,
            backgroundColor: Dark.buy,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            marginRight: 20,
          }}
        >
          {loading ? (
            <Loading type="spin" color={Dark.text} height={20} width={20} />
          ) : (
            "ALL SQ ACTIVE"
          )}
        </div>
        <div
          onClick={symbol_future_all_sq_deactive}
          style={{
            height: 40,
            width: 140,
            backgroundColor: Dark.sell,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            marginRight: 20,
          }}
        >
          {loading ? (
            <Loading type="spin" color={Dark.text} height={20} width={20} />
          ) : (
            "ALL SQ DEACTIVE"
          )}
        </div>

        <div
          onClick={() => setShow(true)}
          style={{
            height: 40,
            width: 120,
            backgroundColor: Dark.buy,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            marginRight: 20,
          }}
        >
          {loading ? (
            <Loading type="spin" color={Dark.text} height={20} width={20} />
          ) : (
            "ADD SYMBOL"
          )}
        </div>
        <div
          onClick={symbol_future_delete_all}
          style={{
            height: 40,
            width: 140,
            backgroundColor: Dark.sell,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            marginRight: 20,
          }}
        >
          {loading ? (
            <Loading type="spin" color={Dark.text} height={20} width={20} />
          ) : (
            "DELETE SYMBOL"
          )}
        </div>


        <div
          onClick={()=>setShow1(true)}
          style={{
            height: 40,
            width: 140,
            backgroundColor: Dark.sell,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            marginRight: 20,
          }}
        >
          {loading ? (
            <Loading type="spin" color={Dark.text} height={20} width={20} />
          ) : (
            "EXPIRY SYMBOL"
          )}
        </div>

        <div
          onClick={run_closing}
          style={{
            height: 40,
            width: 140,
            backgroundColor: Dark.primary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            marginRight: 20,
          }}
        >
          {loading ? (
            <Loading type="spin" color={Dark.text} height={20} width={20} />
          ) : (
            "RUN CLOSING"
          )}
        </div>
      </div>

      <Dialog
        header="ADD FUTURE SMBOL"
        visible={show}
        className="modal"
        style={{ width: "40vw", height: "50vh" }}
        onHide={() => setShow(false)}
      >
        <AddSymbol
          close={() => {
            setShow(false);
            props.load();
          }}
        />
      </Dialog>
      <Dialog
        header="EXPIRY FUTURE SMBOL"
        visible={show1}
        className="modal"
        style={{ width: "40vw", height: "40vh" }}
        onHide={() => setShow1(false)}
      >
        <ExpirySymbol
          close={() => {
            setShow1(false);
            props.load();
          }}
        />
      </Dialog>
    </div>
  );
}
